/*
@font-face {
  font-family: 'Calibre';
  src: url('../assest/fonts/calibre/calibre-web-light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CalibreSemiBold';
  src: url('../assest/fonts/calibre/calibre-web-semibold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CalibreBold';
  src: url('../assest/fonts/calibre/calibre-web-bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FinancierDisplay';
  src: url('../assest/fonts/financier-display/financier-display-web-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
*/

h1,h2,h3,h5   {
  font-family: "FinancierDisplay", sans-serif !important;
  color:#435254 !important
}

p,body   {
  font-family: "Calibre", sans-serif !important;
  /* font-family: "FinancierDisplay", sans-serif !important; */
  color:#435254 !important
}
a   {
  font-family: "CalibreBold", sans-serif !important;
  /* font-family: "FinancierDisplay", sans-serif !important; */
  color:#435254 !important
}

td,th   {
  font-family: "CalibreSemiBold", sans-serif !important;
  /* font-family: "FinancierDisplay", sans-serif !important; */
  color:#435254 !important
}

.header-menu{
  font-family: "Calibre", sans-serif !important;
  padding: 0px !important;
  text-decoration: none;
  /* font-family: "FinancierDisplay", sans-serif !important; */
  /* color:#435254 !important */
}


/* h1,h2,h3,h5,p,a,td   {
  font-family: "Calibre", sans-serif !important;
} */

.header-container {
  height: 60px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: rgb(31, 73, 125);
  margin-bottom: 30px;

}

.header-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.header-buttons .btn {
  font-size: 1rem;
  margin: 0 5px;
  background: rgb(31, 73, 125);
  border: none;
}

.responsive-fixed {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1030;
}

/* For extra small screens and up, fixed at the bottom */
.responsive-fixed {
  bottom: 0;
}

/* For medium screens and up, fixed at the top */
@media (min-width: 768px) {
  .responsive-fixed {
    top: 0;
  }
}

.dropdown-menu {
  min-width: 150px;
  text-align: left;
}

.carousel-background {
  max-width: 600px;
  margin: 0 auto;
  border-radius: 15px;
}

.carousel-container {
  width: 100%;
}

.image-container {
  width: 83%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
}

.carousel-image {
  width: auto;
  height: 100%;
  max-height: 300px;
  max-width: 121%;
  border-radius: 10px;
}



.CentercardButton {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 5px;
  background: white;
  width: 160px;
  color:#000000;
  border-radius: 5px ;
  border: 2px solid #003f2d;
  padding: 9.5px;
}

.Table-side {
  background: rgb(31, 73, 125);
  color: white;
}

.map-container {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.address {
  /* font-family: "Calibre", sans-serif; */
  /* font-size: 18px; */
  /* color: #080606; */
  /* font-weight: 330; */
  margin-top: 10px;
}

.table-scrollable {
  max-height: 500px;
  overflow-y: auto;
}

.centercardheader {
  background-color: #20437c;
}

.centercard {
  margin-left: 15px;
  margin-top: 40px;
  width: 400px;
}

.centercardtablehead {
  background-color: #20437c;
  color: white;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.centercardtable {
  width: 500px;
  margin-top: 1rem;
  border-collapse: collapse;
}

.centercardtable th,
.centercardtable td {
  text-align: start;
  vertical-align: middle;
  padding: 10px;
}


.custom-carousel-container {
  max-width: 100%;
  margin: 0 auto;
}

.image-row {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.image-item {
  flex: 1;
  max-width: 30%;
  text-align: center;
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

.image-item img:hover {
  transform: scale(1.05);
}

/* General tabs styling */
.nav-tabs {
  border-bottom: 0px solid #ddd;
  justify-content: flex-start;
}

.nav-tabs .nav-link {
  /* font-size: 1.3rem; */
  color: #393939;
  /* margin-right: 1rem; */
  /* padding: 0.5rem 1rem; */
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  transition: all 0.3s ease;
}

.nav-tabs .nav-link.active {
  color: #000000;
  border-bottom: 2px solid #003f2d;
}



.carousel-indicators .active {
  transform: scale(1.2);
  box-sizing: inherit;

}

.custom-carousel-container .carousel-control-prev,
.custom-carousel-container .carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
}




/* Clientbase */

.Clientbase1 {
  /* font-weight: 600; */
  /* margin-left: 40px;
  margin-bottom: 20px; */
}

.Clientbaseimg {
  border-radius: 10px;
  width: 860px;
}

/* @media (min-width: 1324px) and (max-width: 1450px) {

  .Clientbase1 {
    font-weight: 600;
    margin-right: 327px;
    margin-bottom: 20px;
  }

  .Clientbaseimg {
    border-radius: 10px;
    width: 1010px;
  }
} */

.clientbase2 {
  /* border: 1px solid rgb(224, 222, 222); */
  /* margin-left: 40px; */
  /* padding: 10px; */
  border-radius: 3px;
}


/* conculsion */

.conculsionlogo {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 329.879px;
  height: 93.4646px;
  transform: translate(-16.5401px, 94.8px);
}

.conculsionh1 {
  transform: translate(-0.4599px, 122.8px);
  font-weight: 500;
}

.conculsioncontent {
  font-family: "IBM Plex Sans", sans-serif;
  text-align: justify;
  transform: translate(-13.5401px, 132.8px);
}

.name {
  font-size: 26px;
}

.span {
  font-size: x-large;
}

.conculsionimage {
  transform: translate(-26.4599px, 60.8px);
}

.arrowicon {
  height: 20px;
}


.tableicon-head {
  /* font-size: 19px; */
  /* font-size: 1.2rem; */
  /* font-family: "CalibreSemiBold" !important; */
  /* font-family: "IBM Plex Sans", sans-serif; */
}

.tableicon-content {
  font-size: 1.0rem;
  color:#000000;
  /* font-size: 19px; */
  /* font-family: "IBM Plex Sans", sans-serif; */
}

.terms-content {
  font-size: 19px;

}

.testimonail-img {
  width: 280px;
}

.address-table {
  width: 100%;
  border-collapse: collapse;
}

.last-test-img {
  transform: translate(286.4599px, 20.8px);

}

.location-table {
  border-collapse: collapse;
}

.location-column {
  position: sticky;
  left: 0;
  background-color: #e6eaea;
  z-index: 1;
  /* border: 1px solid #ddd; */
}

.download-btn {
  background: none;
  border: none;
  padding: 0;
  font-family: "Calibre", sans-serif !important;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.download-btn:hover {
  opacity: 0.8;
}

/* Hide entire header in mobile devices */
@media screen and (max-width: 768px) {
  header.fixed-top {
    display: none !important;
  }
  
  /* Adjust body padding since header is hidden */
  body {
    padding-top: 0 !important;
  }
}
