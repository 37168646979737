body {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.loadernew {
  width: 230px;
  height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.5);
}

.loader-head{
  width: 100%;
}