.option-details {
    /* Default styles for mobile */
    max-height: none;
    overflow-y: visible;
}

/* Desktop styles */
@media screen and (min-width: 769px) {
    .option-details {
        max-height: 600px;
        overflow-y: auto;
    }
}

/* Customize scrollbar for desktop */
@media screen and (min-width: 769px) {
    .option-details::-webkit-scrollbar {
        width: 8px;
    }

    .option-details::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
    }

    .option-details::-webkit-scrollbar-thumb {
        background: #012a2d;
        border-radius: 4px;
    }

    .option-details::-webkit-scrollbar-thumb:hover {
        background: #435254;
    }
}

.feedback-cardButton {
    padding: 8px 16px;
    font-weight: 600;
    border: 2px solid #1F497D;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}
.feedback-cardButton:hover {
    background-color: #f8f9fa;
    border-color: #999;
}

.CentercardButton {
    padding: 8px 16px;
    border: 2px solid #1F497D;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}

.CentercardButton:hover {
    background-color: #f8f9fa;
    border-color: #999;
}

.CentercardButton .fa-heart {
    color: #ff0000;
    transition: color 0.3s ease;
}

.CentercardButton.shortlisted {
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
}

.CentercardButton.shortlisted:hover {
    background-color: #6c757d;
    border-color: #6c757d;
}

.CentercardButton.shortlisted .fa-heart,
.CentercardButton.shortlisted .text-white {
    color: #ffffff !important;
}

.shortlist-count {
    font-size: 12px;
    opacity: 0.9;
    font-weight: normal;
}

.shortlist-summary {
    padding: 20px;
    border-radius: 8px;
    margin-top: 50px;
}

.shortlist-title {
    font-size: 2.5rem;
    color: #1a2b3c;
    margin-bottom: 30px;
    font-weight: 600;
}

.shortlist-table {
    width: 100%;
    background: white;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #a09f9f;


}

.shortlist-table thead {
    background: #1e4976;
    color: white;
    
}

.shortlist-table th {
    font-weight: 600;
    text-align: left;
    background: #ddd;
    border: 1px solid #a09f9f;
}

.shortlist-table td {
    vertical-align: middle;
    border: 1px solid #a09f9f;


}

.shortlist-photo {
    width: 150px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.space-name {
    color: #1e4976;
    text-decoration: underline;
    font-weight: 500;
}

.shortlisted-by {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.shortlist-user {
    background: #f8f9fa;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
}

.email-tag {
    border: 1.5px solid #1F497D;
    color: white;
    padding: 5px 12px;
    border-radius: 20px;
    font-size: 0.9em;
    white-space: nowrap;
    font-weight: 500;
    color: #333;
}

.shortlist-user small {
    font-size: 11px;
    margin-top: 2px;
}

.schedule-cell {
    min-width: 200px;
}

.current-schedule {
    background: #f8f9fa;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
}

.current-schedule small {
    display: block;
    line-height: 1.5;
    color: #333;
}

.schedule-advice {
    border: 1px solid #e9ecef;
}

.schedule-advice p {
    font-size: 0.9rem;
}

.schedule-advice .text-primary {
    color: #0d6efd !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .shortlist-table {
        display: block;
        overflow-x: auto;
    }

    .shortlist-photo {
        width: 100px;
        height: 70px;
    }
}