.background-image {
    background-image: "../../assest/image/thank-you.png";
    /* background-color: aquamarine; */
}

.conclusion-section {
    position: relative;
    /* background-image: url('../assest/image/footer.png'); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: #012a2d;  */
    min-height: 100vh;
    padding: 60px 0;
    display: flex;
    align-items: center;
}

.conclusion-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(1, 42, 45, 0.85), rgba(1, 42, 45, 0.95));
    z-index: 1;
}

.conclusion-section .container {
    z-index: 2;
    width: 100%;
}

.conclusion-section .row {
    margin: 0;
    padding: 20px;
    min-height: calc(100vh - 120px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .conclusion-section {
        padding: 40px 0;
        background-size: 90% auto;
    }
    
    .conclusion-section .row {
        min-height: auto;
    }
}

/* Animation for text */
.conclusion-section h1,
.conclusion-section p {
    position: relative;
    transition: opacity 0.3s ease;
}

.conclusion-section h1:hover,
.conclusion-section p:hover {
    opacity: 0.9;
}