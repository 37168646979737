.table-wrapper {
  overflow-x: auto;
  /* width: 70%; */
}

.table-values{
  font-size: 0.9rem;
}

.scrollable-table {
  overflow-x: auto;
  max-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.scrollable-table th,
.scrollable-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  white-space: nowrap;
}

.sticky-column {
  position: sticky;
  left: 0;
  /* background-color: #003f2d; */
  background-color: #e6eaea;
  z-index: 1;
  border-right: 2px solid #ddd;
  color: black;
}

.header {
  background-color: #e6eaea;
  position: sticky;
  top: 0;
  z-index: 2;
}

.recommed-title {
  margin-left: 100px;
}

/* Drag and drop styles */
.header td[data-rbd-droppable-id] {
  padding: 0 !important;
}

.header td[data-rbd-droppable-id] > div {
  display: flex;
  width: 100%;
}

.header td[data-rbd-droppable-id] > div > div {
  flex: 1;
  padding: 12px;
  cursor: move;
  user-select: none;
  transition: background-color 0.2s;
}

.header td[data-rbd-droppable-id] > div > div:hover {
  background-color: rgba(230, 234, 234, 0.5);
}

.header td[data-rbd-droppable-id] > div > div[data-rbd-dragging-draggable-id] {
  background-color: rgba(230, 234, 234, 0.8) !important;
}

/*
@media (max-width: 426px) {
  .table-wrapper {
    overflow-x: auto;
    width: 330px;
    margin-left: 3px;
  }

  .scrollable-table {
    overflow-x: auto;
    width: 531px;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .scrollable-table th,
  .scrollable-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    white-space: nowrap;
  }
}
*/